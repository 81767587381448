import ApiEasycatService from '@/api/new/services/api.easycat.service'

const state = {
  termBanks: [],
  tbFilter: {},
  tbPages: {
    current: 1,
    total: 1,
    totalSegments: 10,
    pageSize: 10,
    from: 1,
    to: 10
  },
  currentTb: null,
  tbSegments: [],
  tbSegmentsPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 10
  },
  tbLanguages: [],
  selectedTbs: [],
  selectAllFiltered: false
}

const mutations = {
  setTermBanks(state, tbs) {
    state.termBanks = tbs
  },
  updateTbFilter(state, { value, type }) {
    if (type && state.tbFilter[type] !== value) {
      state.tbFilter = {
        ...state.tbFilter,
        [type]: value
      }
      state.tbPages.current = 1
    }
  },
  setTbPages(state, tbPages) {
    state.tbPages.current = tbPages.current_page
    state.tbPages.total = tbPages.last_page
    state.tbPages.totalSegments = tbPages.total
    state.tbPages.pageSize = tbPages.per_page
    state.tbPages.from = tbPages.from
    state.tbPages.to = tbPages.to
  },
  setCurrentTb(state, tm) {
    state.currentTb = tm
  },
  setTbSegmentsPages(state, tbPages) {
    state.tbSegmentsPages.current = tbPages.current_page
    state.tbSegmentsPages.total = tbPages.last_page
    state.tbSegmentsPages.totalSegments = tbPages.total
    state.tbSegmentsPages.pageSize = tbPages.per_page
  },
  setTbSegments(state, segments) {
    state.tbSegments = segments
  },
  setTbLanguages(state, lang) {
    state.tbLanguages = lang
  },
  selectTb(state, tm) {
    if (!state.selectedTbs.includes(tm)) {
      state.selectedTbs = [...state.selectedTbs, tm]
    }
  },
  deselectTb(state, tm) {
    const index = state.selectedTbs.indexOf(tm)
    if (index > -1) {
      let result = [...state.selectedTbs]
      result.splice(index, 1)
      state.selectedTbs = result
    }
  },
  setSelectedTbs(state, tms) {
    state.selectedTbs = tms
  },
  setSelectAll(state, bool) {
    state.selectAllFiltered = bool
  }
}

const actions = {
  // Term Banks
  async getStateTbLanguages({ state, dispatch }) {
    if (state.tbLanguages.length === 0) await dispatch('getTbLanguages')
  },
  async getTbLanguages({ commit, rootGetters }) {
    if (state.tbLanguages.length > 0) return
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/languages`
    )
      .then((res) => {
        const langs = res.data
        commit('setTbLanguages', langs)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getTermBanks({ state, commit, rootGetters }, payload) {
    const params = new URLSearchParams()
    params.append('page', payload?.page || state.tbPages.current)
    if (Object.values(state.tbFilter).length > 0) {
      Object.keys(state.tbFilter).forEach((key) => {
        if (state.tbFilter[key]) {
          params.append(key, state.tbFilter[key])
        }
      })
    }
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks`,
      { params }
    )
      .then((res) => {
        commit('setTermBanks', res.data.data)
        commit('setTbPages', res.data.meta)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async importTb({ rootGetters }, payload) {
    const data = new FormData()
    data.append('data[type]', 'term_banks')
    data.append(`data[attributes][name]`, payload.name)
    for (let y = 0; y < payload.files.length; y++) {
      data.append('data[attributes][files][]', payload.files[y])
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/import-from-csv`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async createTb({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks`,
      data
    )
      .then(async () => {
        await dispatch('getTermBanks')
        await dispatch('getTbLanguages')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateTb({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${payload.id}`,
      data
    )
      .then(async () => {
        await dispatch('getTermBanks')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteTb({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          ids: payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/destroy`,
      data
    )
      .then(async () => {
        await dispatch('getTermBanks')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async exportTb({ rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          ids: payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/export`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async getTb({ commit, dispatch, rootGetters }, id) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/${id}`
    )
      .then(async (res) => {
        commit('setCurrentTb', res.data.data)
        await dispatch('getTbSegments', id)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getTbSegments({ commit, rootGetters }, id) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/${id}/terms`
    )
      .then((res) => {
        commit('setTbSegments', res.data.data)
        commit('setTbSegmentsPages', res.data.meta)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async selectAll({ state, commit }, payload) {
    commit('setSelectAll', payload.isSelected)
    if (payload.overview) {
      const tms = state.termBanks.map((tm) => tm.id)
      if (payload.isSelected) commit('setSelectedTbs', tms)
      else commit('setSelectedTbs', [])
    } else {
      const tbSegments = state.tbSegments.map((s) => s.id)
      if (payload.isSelected) commit('setSelectedTbs', tbSegments)
      else commit('setSelectedTbs', [])
    }
  }
}

export const termBanks = {
  namespaced: true,
  state,
  actions,
  mutations
}
